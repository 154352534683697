<template>
  <div class="create-form-container">
    <admin-header-vue alias pageName="Add New Company" />
    <form @submit.prevent="handleSubmit" autocomplete="new-password">
      <div class="create-form">
        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isGeneralDetailsAccordianOpen"
            @click="isGeneralDetailsAccordianOpen = !isGeneralDetailsAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        General Details
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                  <div class="columns">
                      <div class="column">
                        <div class="display-flex">
                          <upload-vue
                            label="Company Logo (Light)"
                            :submitted="submitted"
                            :image="newCompany.properties.logo_light"
                            @onChangeImage="onChangeLightLogo" />
                          <upload-vue
                            label="Company Logo (Dark)"
                            :submitted="submitted"
                            :image="newCompany.properties.logo_dark"
                            @onChangeImage="onChangeDarkLogo" />
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-4">
                        <input-vue
                          :value="newCompany.name"
                          :required="true"
                          :min="1"
                          type="text"
                          :max="250"
                          :submitted="submitted"
                          @onChangeName="onChangeName"
                          @focus="handleFocus"
                          :readonly="true"
                          label="Name" />
                      </div>

                      <div class="column is-4">
                        <input-vue
                          :value="newCompany.displayName"
                          :required="false"
                          :min="1"
                          type="text"
                          :max="250"
                          :submitted="submitted"
                          @onChangeName="onChangeDisplayName"
                          @focus="handleFocus"
                          :readonly="true"
                          label="Display Name" />
                      </div>

                      <div class="column is-4">
                        <input-vue
                          :value="newCompany.duns"
                          :required="false"
                          :min="9"
                          type="text"
                          :isDuns="true"
                          :max="9"
                          :submitted="submitted"
                          @onChangeName="onChangeDUNS"
                          @focus="handleFocus"
                          :readonly="true"
                          label="DUNS" />
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-4">
                        <input-vue
                          label="Alias (comma separated values)"
                          :value="tempAlias"
                          :required="false"
                          :max="250"
                          type="textarea"
                          :submitted="submitted"
                          placeholder="Alias 1, Alias 2"
                          @focus="handleFocus"
                          :readonly="true"
                          @onChangeName="handleAliasInput" />
                      </div>

                      <div class="column is-1 form-group">
                        <p>Is Parent?</p>
                        <b-checkbox v-model="newCompany.isParent"></b-checkbox>
                      </div>

                      <div class="column is-3 form-group relative" v-if="!newCompany.isParent">
                        <p>Company Parent<sup class="display-flex required">*</sup></p>
                        <v-select
                          label="companyName"
                          placeholder="Type to search.."
                          :options="companyParentList"
                          :loading="loadingParentCompanies"
                          @search="handleCompanyParentSearch"
                          :clearSearchOnSelect="true"
                          :value="selectedCompany"
                          @input="onChangeParentCompany"
                          :filterable="false">
                          <template slot="no-options">
                            Type to search..
                        </template></v-select>
                        <span v-if="isParentCompanyIdInvalid" class="required-error">Please select parent.</span>

                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-4 form-group">
                        <p>Is Public?</p>
                        <b-checkbox v-model="newCompany.isPublic"></b-checkbox>
                      </div>
                      <div class="column is-4">
                        <div v-if="newCompany.isPublic" class="add-new-button address-btn">
                          <b-button @click.prevent="addNewExchange">Add New Listing</b-button>
                        </div>
                      </div>
                    </div>

                    <div v-if="newCompany.isPublic">
                      <div v-for="(exchange,index) of newCompany.affiliatedExchanges" :key="index + exchange.exchangeId" class="columns">
                        <div class="column is-4 form-group">
                          <select-vue
                          :key="'exchange' + exchange.exchangeId"
                          :value="exchange.exchangeId"
                          label="Select affiliated exchange"
                          placeholder="Select a affiliated exchange"
                          :list="localExchangeList"
                          :submitted="submitted"
                          :required="newCompany.isPublic"
                          :fieldIndex="index"
                          @onChangeSelection="onChangeOfExchangeList"
                          id="id"
                          name="label" />
                        </div>
                        <div class="column is-4 form-group">
                          <input-vue
                            label="Ticker"
                            :required="newCompany.isPublic"
                            type="text"
                            :max="250"
                            :submitted="submitted"
                            placeholder="Add Ticker"
                            :fieldIndex="index"
                            :value="exchange.ticker"
                            @focus="handleFocus"
                            :readonly="true"
                            @onChangeName="handleTickerInput" />
                        </div>
                        <div class="column is-4 form-group">
                          <input-vue
                            label="ISIN"
                            type="text"
                            :min="12"
                            :max="12"
                            :isISIN="true"
                            :submitted="submitted"
                            placeholder="Enter ISIN"
                            :fieldIndex="index"
                            :value="exchange.isin"
                            @focus="handleFocus"
                            :readonly="true"
                            @onChangeName="handleISINInput" />
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-4 form-group">
                        <p>Primary Industry</p>
                        <treeselect
                          :multiple="true"
                          :options="primaryIndustryOptions"
                          :sort-value-by="sortValueBy"
                          :default-expand-level="1"
                          :limit="tagItemShowLimit"
                          :flat="true"
                          placeholder=""
                          :value-consists-of="valueConsistsOf"
                          v-model="newCompany.primaryIndustries" />
                      </div>

                      <div class="column is-4 form-group">
                        <p>Secondary Industry</p>
                        <treeselect
                          :multiple="true"
                          :options="secondaryIndustryOptions"
                          :sort-value-by="sortValueBy"
                          :default-expand-level="1"
                          :limit="tagItemShowLimit"
                          :flat="true"
                          placeholder=""
                          :value-consists-of="valueConsistsOf"
                          v-model="newCompany.secondaryIndustries" />
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-4">
                          <div class="form-group">
                            <p>Date Started</p>
                            <b-datepicker
                              icon-right-clickable
                              @icon-right-click="clearDate"
                              icon-right="close-circle"
                              :placeholder="foundedDatePlaceHolder"
                              class="form-control"
                              v-model="creationDate"
                              icon="calendar-today"
                              @change-year="handleYearChange"
                              @change-month="handleMonthChange"
                              :unselectable-dates="noFutureDates"
                              trap-focus>
                            </b-datepicker>
                          </div>
                      </div>
                      <div class="column is-4">
                          <div class="form-group">
                            <p>Date Incorporated</p>
                            <b-datepicker
                              icon-right-clickable
                              @icon-right-click="clearIncDate"
                              icon-right="close-circle"
                              :placeholder="incorporatedDatePlaceholder"
                              class="form-control"
                              v-model="incorporatedDate"
                              icon="calendar-today"
                              @change-year="handleIncDateYearChange"
                              @change-month="handleIncDateMonthChange"
                              :unselectable-dates="noFutureDates"
                              trap-focus>
                            </b-datepicker>
                          </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-4 form-group">
                        <select-vue
                          :value="newCompany.fyeMonth"
                          label="Fiscal Year Ends In"
                          placeholder="Select a Month"
                          :list="monthList"
                          :submitted="submitted"
                          :required="false"
                          @onChangeSelection="onChangeFYEMonth"
                          id="value"
                          name="label" />
                      </div>

                      <div class="column is-4">
                        <input-vue
                          :value="newCompany.description"
                          :required="false"
                          type="textarea"
                          :max="500"
                          :submitted="submitted"
                          @onChangeName="handleDescriptionInput"
                          placeholder="Description"
                          @focus="handleFocus"
                          :readonly="true"
                          label="Description" />
                      </div>
                    </div>
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isStockInfoAccordianOpen"
            @click="isStockInfoAccordianOpen = !isStockInfoAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        Financials
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                  <div class="column is-12">
                    <div class="form-group">
                      <p class="display-flex">Revenue</p>
                    <band-per-year-card
                      :dataList="newCompany.revenue"
                      :allYearData ="revenueYearData"
                      @yearInput="(data, isInvalid) => handleRevenueYear(data, isInvalid)"
                      @bandInput="(data) => handleRevenueBand(data)"
                      :bandList="revenueBandList"
                      bandName="Revenue" />
                    </div>
                    <div class="add-new-button address-btn">
                      <b-button @click.prevent="addNewRevenue">Add New Revenue</b-button>
                    </div>
                  </div>
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isEmployeeDetailsAccordianOpen"
            @click="isEmployeeDetailsAccordianOpen = !isEmployeeDetailsAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        Employee Details
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <div class="columns">
                        <div class="column is-12">
                          <div class="form-group">
                            <p class="display-flex">Employee Size</p>
                            <band-per-year-card
                            :bandList="employeeSizeList"
                            :allYearData ="employeeYearData"
                            :dataList="newCompany.employeeSize"
                            @yearInput="(data, isInvalid) => handleYearInput(data, isInvalid)"
                            @bandInput="(data) => handleBandInput(data)"
                            bandName="Employee Size" />
                            <div class="add-new-button address-btn">
                                <b-button @click.prevent="addNewEmployeesCount">Add New Employee Count</b-button>
                            </div>
                            </div>
                        </div>
                      </div>
                       <div class="column is-12">
                        <modal-vue @onChange="onChangeKeyPersons" :full-screen="true" :modal="keyPersonsModal" :backdropClosed="true">
                          <div class="details-modal">
                            <div class="details-inside">
                              <admin-header-vue
                                alias=""
                                :close="true"
                                @onClose="onChangeKeyPersons(false)"
                                pageName="Key Persons Details" />
                                <div class="form-group">
                                    <div>
                                    <div class="columns">
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.firstName"
                                          :min="1"
                                          type="text"
                                          :max="250"
                                          :required="isKeyPersonObjInvalid"
                                          :submitted="submitted"
                                          @onChangeName="onKeyPersonFirstNameChanged"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="First Name" />
                                      </div>
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.lastName"
                                          :required="false"
                                          :min="1"
                                          type="text"
                                          :max="250"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonSecondName"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Last Name" />
                                      </div>
                                    </div>

                                    <div class="columns">
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.designation"
                                          :required="false"
                                          :min="1"
                                          type="text"
                                          :max="250"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonDesignation"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Designation" />
                                      </div>
                                      <div class="column is-4">
                                        <p class="mb-0">Joining Date</p>
                                        <b-datepicker
                                          icon-right-clickable
                                          @icon-right-click="clearJoiningDate"
                                          icon-right="close-circle"
                                          :placeholder="joiningDatePlaceHolder"
                                          class="form-control"
                                          v-model="joiningDate"
                                          icon="calendar-today"
                                          @change-year="handleJoiningYearChange"
                                          @change-month="handleJoiningMonthChange"
                                          :unselectable-dates="noFutureDates"
                                          trap-focus>
                                        </b-datepicker>
                                      </div>
                                    </div>

                                    <div class="columns">
                                      <div class="column is-4">
                                        <search-drop-down
                                          label="Reports To"
                                          :showLabel="true"
                                          :list="localSuperiorList"
                                          :selected="selectedSuperior"
                                          @onChange="HandleKeyPersonReportsTo" />
                                      </div>
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.biography"
                                          :required="false"
                                          :min="1"
                                          type="textarea"
                                          :max="500"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonBiography"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Biography" />
                                      </div>
                                    </div>

                                    <div class="columns">
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.phone"
                                          :required="false"
                                          :min="6"
                                          type="number"
                                          :isPhone="true"
                                          :max="20"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonPhone"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Phone" />
                                      </div>
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.email"
                                          :required="false"
                                          :min="1"
                                          type="email"
                                          :max="100"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonEmail"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Email" />
                                      </div>
                                    </div>

                                    <div class="columns">
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.website"
                                          :required="false"
                                          :min="1"
                                          type="url"
                                          :max="250"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonWebsite"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Website" />
                                      </div>
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.linkedin"
                                          :required="false"
                                          :min="1"
                                          type="url"
                                          :max="250"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonLinkedin"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Linkedin" />
                                      </div>
                                    </div>

                                    <div class="columns">
                                      <div class="column is-4">
                                        <input-vue
                                          :value="keyPersonObj.twitter"
                                          :required="false"
                                          :min="1"
                                          type="url"
                                          :max="250"
                                          :submitted="submitted"
                                          @onChangeName="HandleKeyPersonTwitter"
                                          @focus="handleFocus"
                                          :readonly="true"
                                          label="Twitter" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="!showKeyPersonUpdateBtn" class="columns add-new-button address-btn">
                                  <b-button :disabled="checkValidKeyPersonsState" @click.prevent="appendKeyPersonObj">Add New Key Person</b-button>
                                </div>
                                <div v-else class="columns add-new-button address-btn">
                                  <b-button :disabled="checkValidKeyPersonsState" @click.prevent="updateKeyPerson">Update Key Person</b-button>
                                </div>
                              </div>
                          </div>
                        </modal-vue>

                        <div class="columns add-new-button address-btn">
                          <b-button @click.prevent="openNewKeyPersonModal">Add New Key Person</b-button>
                        </div>

                        <div v-if="newCompany.keyPersons && newCompany.keyPersons.length > 0">
                          <key-persons-table :formdata="keyPersonsTableData" @openKeyPersonsModal="openKeyPersonModal" />
                        </div>
                      </div>
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isAddressAccordianOpen"
            @click="isAddressAccordianOpen = !isAddressAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        Addresses
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
              <div class="content">
                <modal-vue @onChange="onChangeAddressModal" :full-screen="true" :modal="addressModal" :backdropClosed="true">
                  <div class="details-modal">
                    <div class="details-inside">
                      <admin-header-vue
                        alias=""
                        :close="true"
                        @onClose="onChangeAddressModal(false)"
                        pageName="Address Details" />
                      <div class="address-container">
                          <div class="columns">
                            <div class="column is-4">
                              <select-vue
                                label="Address Types"
                                placeholder="Address Type"
                                :list="addressTypes"
                                :submitted="addressSubmitted"
                                :required="false"
                                @onChangeSelection="onChangeOfAddressType"
                                @onDeselect="onDeselectOfAddressType"
                                id="value"
                                name="label" />
                            </div>
                            <div class="column is-4">
                              <input-vue
                                :value="addressObj.addressFirstLine"
                                :required="false"
                                :min="1"
                                type="text"
                                :max="250"
                                :submitted="addressSubmitted"
                                @onChangeName="HandleAddressFirstLine"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Address Line 1" />
                            </div>
                          </div>

                          <div class="columns">
                            <div class="column is-4">
                              <input-vue
                                :value="addressObj.addressSecondLine"
                                :required="false"
                                :min="1"
                                type="text"
                                :max="250"
                                :submitted="addressSubmitted"
                                @onChangeName="HandleAddressSecondLine"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Address Line 2" />
                            </div>
                            <div class="column is-4">
                              <input-vue
                                :value="addressObj.landmark"
                                :required="false"
                                :min="1"
                                type="text"
                                :max="250"
                                :submitted="addressSubmitted"
                                @onChangeName="HandleAddressLandmark"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Landmark" />
                            </div>
                          </div>

                          <div class="columns">
                            <div class="column is-4">
                              <search-drop-down
                              :submitted="addressSubmitted"
                              :required="isAddressObjInvalid"
                              label="Country"
                              :showLabel="true"
                              :list="localCountriesList"
                              :selected="selectedCountry"
                              @onChange="onChangeCountryName" />
                            </div>
                            <div class="column is-4">
                              <search-drop-down
                              :submitted="addressSubmitted"
                              :required="false"
                              label="State"
                              :showLabel="true"
                              :list="statesListLocal"
                              :selected="selectedState"
                              @onChange="onChangeStateName" />
                            </div>
                          </div>

                          <div class="columns">
                            <div class="column is-4">
                              <input-vue
                                :value="addressObj.city"
                                :required="false"
                                :min="1"
                                type="text"
                                :max="250"
                                :submitted="addressSubmitted"
                                @onChangeName="HandleAddressCity"
                                @focus="handleFocus"
                                :readonly="true"
                                label="City" />
                            </div>
                            <div class="column is-4">
                              <input-vue
                                :value="addressObj.zipCode"
                                :required="false"
                                :min="1"
                                :isZipCode = "true"
                                type="text"
                                :max="10"
                                :submitted="addressSubmitted"
                                @onChangeName="HandleAddressZipcode"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Zip Code" />
                            </div>
                          </div>

                          <div class="columns column is-8 is-multiline p-0">
                            <div class="column is-6" v-for="(addressPhone,addressPhoneIndex) of addressObj.phone" :key="'aph' + addressPhoneIndex">
                              <input-vue
                                :value="addressPhone"
                                :required="true"
                                :min="6"
                                :isPhone="true"
                                :isNumeric="true"
                                type="number"
                                :max="20"
                                :submitted="addressSubmitted"
                                :fieldIndex="addressPhoneIndex"
                                @onChangeName="HandleAddressPhone"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Address Phone" />
                            </div>
                            <div class="column is-6" v-for="(addressEmail,addressEmailIndex) of addressObj.email" :key="'email_' + addressEmailIndex">
                              <input-vue
                                :value="addressEmail"
                                :required="true"
                                :min="1"
                                type="email"
                                :max="100"
                                :submitted="addressSubmitted"
                                :fieldIndex="addressEmailIndex"
                                @onChangeName="HandleAddressEmail"
                                @focus="handleFocus"
                                :readonly="true"
                                label="Address Email" />
                            </div>
                          </div>

                          <div class="columns is-multiline ">
                            <div class="column is-2 add-new-button address-btn">
                              <b-button @click.prevent="addNewAddressPhone()"> Add New Phone </b-button>
                            </div>
                            <div class="column is-2 add-new-button address-btn">
                              <b-button @click.prevent="addNewAddressEmail()"> Add New Email </b-button>
                            </div>
                          </div>

                          <div class="columns">
                            <div v-if="!showUpdateBtn" class="column is-2 add-new-button address-btn">
                              <b-button  :disabled="!checkValidState" @click.prevent="addNewAddressObj">Add New Address</b-button>
                            </div>
                            <div v-else class="column is-2 add-new-button address-btn">
                              <b-button :disabled="!checkValidState" @click.prevent="updateAddress">Update Address</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </modal-vue>

                <div class="columns">
                  <div class="column is-2 add-new-button address-btn">
                    <b-button @click.prevent="addNewAddress">Add New Address</b-button>
                  </div>
                </div>
                <div v-if="newCompany.addresses && newCompany.addresses.length > 0">
                  <address-data-table :formdata="addressTableData" :countryData="localCountriesList" :stateData="stateList" @openAddressModal="openUpdateAddress" />
                </div>

                <div class="columns is-multiline phone-container">
                  <div class="column is-4" v-for="(phone,phoneIndex) of newCompany.phone" :key="'p' + phoneIndex">
                    <input-vue
                      :value="phone"
                      :required="false"
                      :min="6"
                      type="number"
                      :max="20"
                      :isPhone="true"
                      :isNumeric="true"
                      :fieldIndex="phoneIndex"
                      :submitted="submitted"
                      @onChangeName="onInputOfPhone"
                      @focus="handleFocus"
                      :readonly="true"
                      label="Phone" />
                  </div>
                  <div class="column is-4" v-for="(email,emailIndex) of newCompany.email" :key="'e' + emailIndex">
                    <input-vue
                      :value="email"
                      :required="false"
                      :min="1"
                      type="email"
                      :max="100"
                      :fieldIndex="emailIndex"
                      :submitted="submitted"
                      @onChangeName="onInputOfEmail"
                      @focus="handleFocus"
                      :readonly="true"
                      label="Email" />
                  </div>
                </div>
              <div class="columns is-multiline ">
                <div class="column is-2 add-new-button address-btn">
                    <b-button @click.prevent="addNewPhone">Add New Phone</b-button>
                </div>

                <div class="column is-2 add-new-button address-btn">
                    <b-button @click.prevent="addNewEmail">Add New Email</b-button>
                </div>
              </div>
              </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isWebsitesAccordianOpen"
            @click="isWebsitesAccordianOpen = !isWebsitesAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        Website & Socials
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                       <websiteAndSocials
                        v-if="websitesLink && websitesLink.length"
                        @linkInput="(isInvalid, data) => handleSocialInput(isInvalid, data)"
                        @websiteInput="(isInvalid, data) => handleWebsiteInput(isInvalid, data)"
                        :submitted="submitted"
                        :websiteLinkValue="newCompany.url"
                        :socialWebsitesList="websitesLink" />
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isCompetitorsAccordianOpen"
            @click="isCompetitorsAccordianOpen = !isCompetitorsAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        Competitors
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <div class="columns">
                      <div class="column is-4">
                        <div class="form-group">
                          <p class="display-flex">Competitors</p>
                            <v-select
                            multiple
                            label="companyName"
                            placeholder="Type to search.."
                            :options="companyCompetitorsList"
                            :loading="loadingCompetitorsCompanies"
                            @search="handleCompanyCompetitorsSearch"
                            :clearSearchOnSelect="true"
                            :value="newCompany.competitors"
                            @input="onChangeCompetitorId"
                            :filterable="false">
                            <template slot="no-options">
                              Type to search..
                          </template></v-select>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column id-12">
            <b-collapse
            class="card"
            animation="slide"
            :open="isVoESourcesAccordianOpen"
            @click="isVoESourcesAccordianOpen = !isVoESourcesAccordianOpen">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button">
                    <p class="card-header-title">
                        VoE Sources
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <companyLinkPerSource
                    v-if="sourceLinkList && sourceLinkList.length"
                    @linkInput="(isInvalid, data) => handleLinkInput(isInvalid, data)"
                    :submitted="submitted"
                    :sourceCompanyLinkList="sourceLinkList" />
                </div>
            </div>
        </b-collapse>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import UploadVue from '../../components/Upload.vue'
import SelectVue from '../../components/Select.vue'
import { monthList, metaTitle, addressTypes, employeeSizeList, revenueBandList } from '../../../../constant/data'
import BandPerYearCard from './BandPerYearCard.vue'
import AddressDataTable from './AddressDataTable.vue'
import KeyPersonsTable from './KeyPersonsTable.vue'
import companyLinkPerSource from './CompanyLinkPerSource.vue'
import websiteAndSocials from './WebsiteAndSocials.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from 'moment'
import Snackbar from '../../../../components/Snackbar'
import SearchDropDown from '../../components/SearchDropDown.vue'
import ModalVue from '../../components/Modal.vue'
import { debounce } from '../../../../util/util'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

/** This component contains the code to add a new product to the database */
export default {
  name: 'AddCompany',
  metaInfo () {
    return {
      title: metaTitle.companies
    }
  },
  components: {
    AdminHeaderVue,
    InputVue,
    UploadVue,
    SelectVue,
    BandPerYearCard,
    AddressDataTable,
    Treeselect,
    companyLinkPerSource,
    SearchDropDown,
    websiteAndSocials,
    KeyPersonsTable,
    ModalVue
  },
  data () {
    return {
      primaryIndustryOptions: [],
      secondaryIndustryOptions: [],
      tagItemShowLimit: 15,
      companyParentList: [],
      companyCompetitorsList: [],
      loadingParentCompanies: false,
      loadingCompetitorsCompanies: false,
      monthList,
      addressModal: false,
      showUpdateBtn: false,
      showKeyPersonUpdateBtn: false,
      isKeyPersonFirstNameInvalid: true,
      isKeyPersonSecondNameInvalid: false,
      isKeyPersonDesignationInvalid: false,
      isKeyPersonBiographyInvalid: false,
      isKeyPersonPhoneInvalid: false,
      isKeyPersonEmailInvalid: false,
      isKeyPersonWebsiteInvalid: false,
      isKeyPersonLinkedinInvalid: false,
      isKeyPersonTwitterInvalid: false,
      keyPersonsModal: false,
      isAddressFirstLineInvalid: true,
      isAddressSecondLineInvalid: false,
      isAddressLandmarkInvalid: false,
      isAddressCityInvalid: true,
      isAddressZipcodeInvalid: true,
      isAddressObjInvalid: true,
      isKeyPersonObjInvalid: false,
      updateAddressIndex: null,
      updatedKeyPersonIndex: null,
      addressTypes,
      addressSubmitted: false,
      isKeyPersonUpdated: false,
      isAliasInvalid: false,
      isRevenueYearsInvalid: false,
      tempAlias: '',
      isEmpSizeYearInvalid: false,
      selectedCompany: {
        id: null,
        companyName: null
      },
      selectedCountry: {
        id: null,
        name: null
      },
      selectedState: {
        id: null,
        name: null
      },
      selectedCity: {
        id: null,
        name: null
      },
      selectedSuperior: {
        id: null,
        name: null
      },
      employeeSizeList,
      revenueBandList,
      newCompany: {
        properties: {
          logo_light: null,
          logo_dark: null
        },
        lightLogoName: null,
        darkLogoName: null,
        name: null,
        displayName: null,
        alias: [],
        isParent: true,
        parentCompanyId: null,
        isPublic: false,
        affiliatedExchanges: [
          {
            exchangeId: null,
            ticker: null,
            isin: null
          }
        ],
        primaryIndustries: [],
        secondaryIndustries: [],
        foundedDate: null,
        incorporatedDate: null,
        fyeMonth: null,
        url: '',
        phone: [],
        email: [],
        description: null,
        sourceLinks: [],
        employeeSize: [
          {
            year: null,
            band: null,
            isDuplicate: false
          }
        ],
        revenue: [
          {
            year: null,
            band: null,
            isDuplicate: false
          }
        ],
        keyPersons: [],
        addresses: [],
        websitesLinks: [],
        voeLinks: [],
        competitors: [],
        duns: null
      },
      addressObj: {
        id: null,
        addressType: null,
        addressFirstLine: null,
        addressSecondLine: null,
        landmark: null,
        countryId: null,
        stateId: null,
        city: null,
        zipCode: null,
        phone: [],
        email: []
      },
      addressPhoneValidity: [],
      addressEmailValidity: [],
      addressType: null,
      selectedExchange: null,
      keyPersonObj: {
        firstName: null,
        lastName: null,
        designation: null,
        joiningDate: null,
        reportsTo: null,
        biography: null,
        phone: null,
        email: null,
        website: null,
        linkedin: null,
        twitter: null
      },
      employeeYearData: [],
      revenueYearData: [],
      sortValueBy: 'ORDER_SELECTED',
      submitted: false,
      isLightLogoInvalid: false,
      isDarkLogoInvalid: false,
      isCompanyNameInvalid: true,
      isDislayNameInvalid: false,
      isParentCompanyIdInvalid: false,
      isDescriptionInvalid: false,
      isEditable: true,
      openPrimaryIndustry: false,
      openSecondaryIndustry: false,
      openCompetitors: false,
      searchCompetitorCompany: null,
      isCompanyUrlInvalid: false,
      isDUNSInvalid: false,
      isCompanyFoundedDateValid: true,
      creationYear: null,
      creationMonth: null,
      creationDate: null,
      incorporatedYear: null,
      incorporatedMonth: null,
      incorporatedDate: null,
      joiningYear: null,
      joiningMonth: null,
      joiningDate: null,
      foundedDatePlaceHolder: 'Select Date',
      foundedDefaultText: 'Select Date',
      incorporatedDatePlaceholder: 'Select Date',
      incorporatedDefaultText: 'Select Date',
      joiningDatePlaceHolder: 'Select Date',
      joiningDefaultText: 'Select Date',
      isGeneralDetailsAccordianOpen: true,
      isAddressAccordianOpen: false,
      isStockInfoAccordianOpen: false,
      isEmployeeDetailsAccordianOpen: false,
      isCompetitorsAccordianOpen: false,
      isWebsitesAccordianOpen: false,
      isVoESourcesAccordianOpen: false,
      anyLinkError: false,
      phoneValidity: [],
      emailValidity: [],
      ISINValidity: [],
      sourceLinkList: [],
      socialLinkList: [],
      valueConsistsOf: 'ALL',
      statesListLocal: [],
      addressTableData: [],
      keyPersonsTableData: []
    }
  },
  computed: {
    ...mapState('company', ['companyList', 'countriesList', 'stateList', 'websitesLink', 'exchangeList']),
    ...mapState('industries', ['allIndustryList']),
    ...mapState('dataSource', ['dataSourceList']),
    ...mapState('user', ['userDetails']),

    /** List of options for key-person's superior selecotor dropdown */
    localSuperiorList () {
      return this.newCompany.keyPersons.filter(p => p.firstName !== this.keyPersonObj.firstName).map(({ firstName: name }, i) => ({ id: i, name }))
    },

    /** List of countries for dropdown on address form */
    localCountriesList () {
      return this.countriesList?.map((x) => ({ id: x.country_id, name: x.country_name }))
    },

    /** List of affiliated exchanges. */
    localExchangeList () {
      return this.exchangeList?.map((x, i) => ({ label: x.name, id: x.se_id }))
    },

    /** Property tracking the validity of allthe fields in the address form. */
    checkValidState () {
      return (
        !this.isAddressFirstLineInvalid &&
        !this.isAddressSecondLineInvalid &&
        !this.isAddressLandmarkInvalid &&
        !this.isAddressCityInvalid &&
        !this.isAddressZipcodeInvalid &&
        this.addressObj.country &&
        !this.addressPhoneValidity.includes(false) &&
        !this.addressEmailValidity.includes(false)
      )
    },

    /** Property tracking the validity of all the fields in the key-person's from. */
    checkValidKeyPersonsState () {
      return (
        this.isKeyPersonFirstNameInvalid ||
        this.isKeyPersonSecondNameInvalid ||
        this.isKeyPersonDesignationInvalid ||
        this.isKeyPersonBiographyInvalid ||
        this.isKeyPersonPhoneInvalid ||
        this.isKeyPersonEmailInvalid ||
        this.isKeyPersonWebsiteInvalid ||
        this.isKeyPersonLinkedinInvalid ||
        this.isKeyPersonTwitterInvalid
      )
    }
  },
  watch: {
    /**
     * A watcher on the new company's isParent property to keep track of the parent company selection and validation.
     * It automatically clears the selected parent company details when the isParent property is set to true
     */
    'newCompany.isParent' () {
      if (this.newCompany.isParent) {
        this.newCompany.parentCompanyId = null
        this.selectedCompany = {
          id: null,
          companyName: null
        }
        this.isParentCompanyIdInvalid = false
      } else {
        if (!this.newCompany.parentCompanyId) {
          this.isParentCompanyIdInvalid = true
        } else {
          this.isParentCompanyIdInvalid = false
        }
      }
    },

    /** A watcher on the new company's isPublic property, It is responsible for clearing the affiliated exchange info when the property value is changed to false. */
    'newCompany.isPublic' () {
      if (!this.newCompany.isPublic) {
        this.newCompany.affiliatedExchanges = [
          {
            exchangeId: null,
            ticker: null,
            isin: null
          }
        ]
        this.ISINValidity = []
      }
    },

    /** A watcher on data source list to populate the company source link input form. */
    dataSourceList () {
      this.sourceLinkList = this.dataSourceList.map(s => {
        return { ...s, link: null }
      })
    },

    /** A watcher on new company's addresses. It is responsible for setting the address table data */
    'newCompany.addresses' () {
      if (this.newCompany.addresses && this.newCompany.addresses.length > 0) {
        const tableDataAddress = this.newCompany.addresses
        this.addressTableData = tableDataAddress
      }
    },

    /** A watcher on the new company's keypersons. It is responsible for setting the key-persons table data */
    'newCompany.keyPersons' () {
      if (this.newCompany.keyPersons && this.newCompany.keyPersons.length > 0) {
        this.keyPersonsTableData = this.newCompany.keyPersons
      }
    },

    /** A watcher on the allIndustryList property to populate the options for the primary and the secondary industry selection dropdowns */
    allIndustryList () {
      this.primaryIndustryOptions = this.allIndustryList ? JSON.parse(JSON.stringify(this.allIndustryList)) : []
      this.secondaryIndustryOptions = this.allIndustryList ? JSON.parse(JSON.stringify(this.allIndustryList)) : []
    },

    /** A watcher on the new company's secondaryIndustries property it iterates through all the industries and disables the selected secondary industries from the primary industry's option list to keep both the lists mutually exclusive */
    'newCompany.secondaryIndustries' () {
      const vue = this
      function iter (level) {
        return function (node) {
          if (vue.newCompany.secondaryIndustries.includes(node.id)) {
            node.isDisabled = true
          } else {
            node.isDisabled = false
          }
          (node.children || []).forEach(iter(level + 1))
        }
      }
      if (this.primaryIndustryOptions.length) { this.primaryIndustryOptions.forEach(iter(0)) }
    },

    /** A watcher on the new company's primaryIndustries property it iterates through all the industries and disables the selected primary industries from the secondary industry's option list to keep both the lists mutually exclusive */
    'newCompany.primaryIndustries' () {
      const vue = this
      function iter (level) {
        return function (node) {
          if (vue.newCompany.primaryIndustries.includes(node.id)) {
            node.isDisabled = true
          } else {
            node.isDisabled = false
          }
          (node.children || []).forEach(iter(level + 1))
        }
      }
      if (this.secondaryIndustryOptions.length) { this.secondaryIndustryOptions.forEach(iter(0)) }
    }
  },
  mounted () {
    this.getAllCountries()
    this.getHeirarchicalIndustryList()
    this.initializeYears()
    this.getSocialInfo()
    this.getExchangeList()
    this.getDataSourceList(this.userDetails.modules.find(m => m.module_name === 'voe').module_id)
  },
  methods: {
    ...mapActions('company', ['addCompany', 'getCompanyList', 'getAllCountries', 'getStatesOfCountry', 'getSocialInfo', 'getExchangeList']),
    ...mapActions('industries', ['getHeirarchicalIndustryList']),
    ...mapActions('dataSource', ['getDataSourceList']),

    /** Method to remove the readonly status from an input field */
    handleFocus (e) {
      e.removeAttribute('readonly')
    },

    /** Method to handle the search for the company in parent company selection dropdown */
    handleCompanyParentSearch (data) {
      if (data) {
        this.loadingParentCompanies = true
      }
      this.debounceParentSearch(data)
    },

    /** Method to handle the search for the company in company competitor selection dropdown */
    handleCompanyCompetitorsSearch (data) {
      if (data) {
        this.loadingCompetitorsCompanies = true
      }
      this.debounceCompetitorsSearch(data)
    },

    /** Method to call the API to get the search result for parent comapny search */
    debounceParentSearch: debounce(async function (data) {
      if (data.length) {
        this.companyParentList = await this.getCompanyList(data)
      }
      this.loadingParentCompanies = false
    }, 800),

    /** Method to call the API to get the search result for comapny competitor search */
    debounceCompetitorsSearch: debounce(async function (data) {
      if (data.length) {
        this.companyCompetitorsList = await this.getCompanyList(data)
      }
      this.loadingCompetitorsCompanies = false
    }, 800),

    /** This method stores the file url and its name for the light theme of the site
     * @public
     */
    onChangeLightLogo ({ file, name }, isInvalid) {
      this.newCompany.properties.logo_light = file
      this.newCompany.lightLogoName = name
      this.isLightLogoInvalid = isInvalid
    },

    /** This method stores the file url and its name for the dark theme of the site
     * @public
     */
    onChangeDarkLogo ({ file, name }, isInvalid) {
      this.newCompany.properties.logo_dark = file
      this.newCompany.darkLogoName = name
      this.isDarkLogoInvalid = isInvalid
    },

    /** This method defines the behavior for when the company name (Input value) is changed
     * @param name {String} - The name of the new company
     * @param isInvalid {Boolean}
     * @public
     */
    onChangeName (name, isInvalid) {
      this.newCompany.name = name
      this.isCompanyNameInvalid = isInvalid
    },

    /** This method defines the behavior for when the company's display name (Input value) is changed
     * @param name {String} - The display name of the new company
     * @param isInvalid {Boolean}
     * @public
     */
    onChangeDisplayName (name, isInvalid) {
      this.newCompany.displayName = name
      this.isDislayNameInvalid = isInvalid
    },

    /** This method defines the behavior for when the company's DUNS (Input value) is changed
     * @param name {String} - The DUNS of the new company
     * @param isInvalid {Boolean}
     * @public
     */
    onChangeDUNS (name, isInvalid) {
      this.isDUNSInvalid = isInvalid
      this.newCompany.duns = name
    },

    /**
     * Gets called on parent company selection/change in "Parent Company" field. It sets the input value as companyId in the newCompany object used for request data.
     * and also fetch the list of topics associated to the selected company to populate the "Parent company Name" field
     * @param {Number} company selected Company object
     * @param {Boolean} isInvalid validity of input
     */
    onChangeParentCompany (company, isInvalid) {
      if (company) {
        this.selectedCompany = company
        this.newCompany.parentCompanyId = company.companyId
        if (this.isParent) {
          this.isParentCompanyIdInvalid = true
        } else {
          this.isParentCompanyIdInvalid = false
        }
      } else {
        this.selectedCompany = {
          id: null,
          companyName: null
        }
        this.newCompany.parentCompanyId = null
        if (!this.isParent) {
          this.isParentCompanyIdInvalid = true
        } else {
          this.isParentCompanyIdInvalid = false
        }
      }
    },

    /**
     * This is a handler function to handel the Alias field input.
     * This function creates the array of aliases from the comma separated value string and set it in new company object.
     * @param value {Object} - Input value
     */
    handleAliasInput (value, isInvalid) {
      this.newCompany.alias = [...new Set(value.split(',').map(d => d.trim()))]
      this.tempAlias = value
      this.isAliasInvalid = isInvalid
    },

    /**
     * This is a handler function to handle addition of new exchanges feilds
     */
    addNewExchange () {
      let isExchangeInvalid = false
      this.newCompany.affiliatedExchanges.forEach((x, index) => {
        if (x.exchangeId === null) {
          Snackbar({ message: 'Please provide exchange name', type: 'is-danger' })
          isExchangeInvalid = true
        } else if (x.ticker === null) {
          Snackbar({ message: 'Please provide ticker name', type: 'is-danger' })
          isExchangeInvalid = true
        } else if (!this.ISINValidity[index]) {
          Snackbar({ message: 'Please provide a valid ISIN value', type: 'is-danger' })
          isExchangeInvalid = true
        } else {
          isExchangeInvalid = false
        }
      })

      if (!isExchangeInvalid) {
        this.newCompany.affiliatedExchanges.push({
          exchangeId: null,
          ticker: null,
          isin: null
        })
        this.ISINValidity.push(true)
      }
    },

    /**
     * This is a handler function to handel the change in the value of financial year end field.
     * This function sets the value of financial year end month to the new comapny object
     * @param value {Object} - Input value
     */
    onChangeOfExchangeList (value, isInvalid, fieldIndex) {
      this.selectedExchange = value
      this.newCompany.affiliatedExchanges[fieldIndex].exchangeId = value
      if (!value) {
        this.newCompany.affiliatedExchanges[fieldIndex].ticker = ''
      }
    },

    /**
     * This is a handler function to handel the Alias field input.
     * This function creates the array of aliases from the comma separated value string and set it in new company object.
     * @param value {Object} - Input value
     */
    handleTickerInput (value, isInvalid, fieldIndex) {
      this.newCompany.affiliatedExchanges[fieldIndex].ticker = value
    },

    /**
     * This is a handler function to handel the Alias field input.
     * This function creates the array of aliases from the comma separated value string and set it in new company object.
     * @param value {Object} - Input value
     */
    handleISINInput (value, isInvalid, fieldIndex) {
      this.$set(this.ISINValidity, fieldIndex, !isInvalid)
      this.newCompany.affiliatedExchanges[fieldIndex].isin = value
    },

    /**
     * This is a filter function to check if the date is a future date or not
     * @param date {Date} - calendar date
     */
    noFutureDates (date) {
      const now = new Date()
      return date >= now
    },

    /**
     * This is a helper function to get a month number starting from 1 instead of 0 from creationMonth
     */
    getMonth (month) {
      return month + 1
    },

    /**
     * This function is a handler function to handel the click on the clear icon on the calendar for founded date
     * This function sets the variable used to save the founded date info to null
     */
    clearDate () {
      this.creationDate = null
      this.creationYear = null
      this.creationMonth = null
      this.foundedDatePlaceHolder = this.foundedDefaultText
    },

    /**
     * A handler function to handel the click on the clear icon on the calendar for incorporated date
     * This function sets the variable used to save the incorporated date info to null
     */
    clearIncDate () {
      this.incorporatedDate = null
      this.incorporatedYear = null
      this.incorporatedMonth = null
      this.incorporatedDatePlaceholder = this.incorporatedDefaultText
    },

    /**
     * This function is a handler function to handel the click on the clear icon on the calendar for key-kerson's joining date
     * This function sets the variable used to save the key-person's joining date to null
     */
    clearJoiningDate () {
      this.joiningYear = null
      this.joiningMonth = null
      this.joiningDate = null
      this.joiningDatePlaceHolder = this.joiningDefaultText
    },

    /**
     * This function is a handler function to handel the change in the year in the key-person's joining date field
     * @param year {number} - selected year
     */
    handleJoiningYearChange (year) {
      this.joiningYear = year <= new Date().getFullYear() ? year : null
      if (this.joiningMonth) {
        this.joiningDatePlaceHolder = year <= new Date().getFullYear() ? this.getMonth(this.joiningMonth) + '-' + this.joiningYear : null
      } else {
        this.joiningDatePlaceHolder = year <= new Date().getFullYear() ? year : this.joiningDefaultText
      }
    },

    /**
     * This function is a handler function to handel the change in the month in the key-person's joining date field
     * @param month {number} - selected month
     */
    handleJoiningMonthChange (month) {
      this.joiningMonth = month
      if (this.joiningYear) {
        this.joiningDatePlaceHolder = this.getMonth(this.joiningMonth) + '-' + this.joiningYear
      }
    },
    /**
     * This function is a handler function to handel the change in the year in the founded date field
     * @param year {number} - selected year
     */
    handleYearChange (year) {
      this.creationYear = year <= new Date().getFullYear() ? year : null
      if (this.creationMonth) {
        this.foundedDatePlaceHolder = year <= new Date().getFullYear() ? this.getMonth(this.creationMonth) + '-' + this.creationYear : null
      } else {
        this.foundedDatePlaceHolder = year <= new Date().getFullYear() ? year : this.foundedDefaultText
      }
    },

    /**
     * This function is a handler function to handel the change in the year in the incorporated date field
     * @param year {number} - selected year
     */
    handleIncDateYearChange (year) {
      this.incorporatedYear = year <= new Date().getFullYear() ? year : null
      if (this.incorporatedMonth) {
        this.incorporatedDatePlaceholder = year <= new Date().getFullYear() ? this.getMonth(this.incorporatedMonth) + '-' + this.incorporatedYear : null
      } else {
        this.incorporatedDatePlaceholder = year <= new Date().getFullYear() ? year : this.incorporatedDefaultText
      }
    },

    /**
     * This function is a handler function to handel the change in the month in the founded date field
     * @param month {number} - selected month
     */
    handleMonthChange (month) {
      this.creationMonth = month
      if (this.creationYear) {
        this.foundedDatePlaceHolder = this.getMonth(this.creationMonth) + '-' + this.creationYear
      }
    },

    /**
     * This function is a handler function to handel the change in the month in the incorporated date field
     * @param month {number} - selected month
     */
    handleIncDateMonthChange (month) {
      this.incorporatedMonth = month
      if (this.incorporatedYear) {
        this.incorporatedDatePlaceholder = this.getMonth(this.incorporatedMonth) + '-' + this.incorporatedYear
      }
    },

    /**
     * This is a handler function to handel the change in the value of financial year end field.
     * This function sets the value of financial year end month to the new comapny object
     * @param value {Object} - Input value
     */
    onChangeFYEMonth (value) {
      this.newCompany.fyeMonth = value
    },

    /**
     * This method handels the input in discription input field and sets the value in the new company object.
     */
    handleDescriptionInput (data, isInvalid) {
      this.newCompany.description = data
      this.isDescriptionInvalid = isInvalid
    },

    /**
     * Method to add the revenue feilds
     */

    addNewRevenue () {
      let isRevenueInvalid = false

      this.newCompany.revenue.forEach(x => {
        if (x.year === null) {
          Snackbar({ message: 'Please provide year', type: 'is-danger' })
          isRevenueInvalid = true
        } else if (x.band === null) {
          Snackbar({ message: 'Please provide revenue band', type: 'is-danger' })
          isRevenueInvalid = true
        } else {
          isRevenueInvalid = false
        }
      })

      if (!isRevenueInvalid) {
        this.newCompany.revenue.push({
          year: null,
          band: null
        })
      }
    },

    /**
     * This is to set the year for revenue
     * @param data {data} - year data for the revenue
     */
    handleRevenueYear (data, isInvalid) {
      this.newCompany.revenue = data
      // this.isRevenueYearsInvalid = isInvalid
    },

    /**
     * This is to set the band for employeeBand
     * @param index {index} - index of the form feild
     * @param data {data} - revenue data of the selected year
     */
    handleBandInput (data) {
      this.newCompany.employeeSize = data
    },

    /**
     * To add the new employees count feilds
     */
    addNewEmployeesCount () {
      let isEmployeeCountValid = false

      this.newCompany.employeeSize.forEach(x => {
        if (x.year === null) {
          Snackbar({ message: 'Please provide year', type: 'is-danger' })
          isEmployeeCountValid = true
        } else if (x.band === null) {
          Snackbar({ message: 'Please provide employee band', type: 'is-danger' })
          isEmployeeCountValid = true
        } else {
          isEmployeeCountValid = false
        }
      })

      if (!isEmployeeCountValid) {
        this.newCompany.employeeSize.push({
          year: null,
          band: null
        })
      }
    },

    /**
     * This is to set the band for revenue
     * @param data {data} - revenue data of the selected year
     */
    handleRevenueBand (data) {
      this.newCompany.revenue = data
    },

    /**
     * This is to set the year for employeeBand
     * @param data {data} - year data for the revenue
     */
    handleYearInput (data, isInvalid) {
      this.newCompany.employeeSize = data
      // this.isEmpSizeYearInvalid = isInvalid
    },

    /**
     * Key persons Data Binding
     *
     * To add the new key persons on button click
     */

    openNewKeyPersonModal () {
      this.keyPersonsModal = true
      this.isKeyPersonObjInvalid = true
      this.showKeyPersonUpdateBtn = false

      this.clearJoiningDate()
      this.keyPersonObj = {
        id: null,
        firstName: null,
        lastName: null,
        designation: null,
        joiningDate: null,
        reportsTo: null,
        biography: null,
        phone: null,
        email: null,
        website: null,
        linkedin: null,
        twitter: null
      }
    },

    /** Method to verify the joining date against the company founded date or the icorporated date if available */
    verifyJoiningDate () {
      this.isKeyPersonObjInvalid = false
      let isJoiningDateBeforeCompanyStart = false
      let joiningDateFormat = ''
      if (this.joiningDate) {
        this.keyPersonObj.joiningDate = moment(new Date(this.joiningDate), 'DD/MM/YYYY').format('YYYY/MM/DD')
        joiningDateFormat = 'YYYY/MM/DD'
      } else {
        if (this.joiningMonth) {
          if (this.joiningYear) {
            this.keyPersonObj.joiningDate = this.joiningYear + '/' + this.getMonth(this.joiningMonth)
            joiningDateFormat = 'YYYY/MM'
          }
        } else if (this.joiningYear) {
          this.keyPersonObj.joiningDate = this.joiningYear.toString()
          joiningDateFormat = 'YYYY'
        } else { this.keyPersonObj.joiningDate = null }
      }
      if (this.creationDate || (this.foundedDatePlaceHolder !== this.foundedDefaultText)) {
        let foundedDate = null
        let joiningDate = null
        joiningDate = moment(this.keyPersonObj.joiningDate, joiningDateFormat)
        if (this.creationDate) {
          foundedDate = moment(this.creationDate)
        } else if (this.foundedDatePlaceHolder !== this.foundedDefaultText) {
          let foundedDateFormat = ''
          if (this.foundedDatePlaceHolder.length > 4) { foundedDateFormat = 'MM-YYYY' } else { foundedDateFormat = 'YYYY' }
          foundedDate = moment(this.foundedDatePlaceHolder, foundedDateFormat)
        }
        if (joiningDate.isBefore(foundedDate)) {
          isJoiningDateBeforeCompanyStart = true
        }
      } else if (this.incorporatedDate || this.incorporatedDatePlaceholder !== this.incorporatedDefaultText) {
        let incorporatedDate = null
        let joiningDate = null
        joiningDate = moment(this.keyPersonObj.joiningDate, joiningDateFormat)
        if (this.incorporatedDate) {
          incorporatedDate = moment(this.incorporatedDate)
        } else if (this.incorporatedDatePlaceholder !== this.incorporatedDefaultText) {
          let incorporatedDateFormat = ''
          if (this.incorporatedDatePlaceholder.length > 4) { incorporatedDateFormat = 'MM-YYYY' } else { incorporatedDateFormat = 'YYYY' }
          incorporatedDate = moment(this.incorporatedDatePlaceholder, incorporatedDateFormat)
        }
        if (joiningDate.isBefore(incorporatedDate)) {
          isJoiningDateBeforeCompanyStart = true
        }
      }
      return isJoiningDateBeforeCompanyStart
    },

    /** Method to add the key-person form data to the keyperson list and it will start showing in the key-person table */
    appendKeyPersonObj () {
      this.isKeyPersonObjInvalid = false
      let isJoiningDateBeforeCompanyStart = false
      isJoiningDateBeforeCompanyStart = this.verifyJoiningDate(this.keyPersonObj)
      if (!isJoiningDateBeforeCompanyStart) {
        this.newCompany.keyPersons.push(this.keyPersonObj)
        this.keyPersonsModal = false
        this.clearJoiningDate()
      } else {
        Snackbar({ message: 'Joining date can not be before company started', type: 'is-danger' })
      }
    },

    /** This method is responsible for the opening hte  key-person modal form and set the meta variables to initial values */
    openKeyPersonModal (data, index) {
      data = JSON.parse(JSON.stringify(data))
      this.selectedSuperior.name = data.reportsTo
      this.keyPersonObj = data
      this.isKeyPersonObjInvalid = true
      this.showKeyPersonUpdateBtn = true
      if (data.joiningDate) {
        this.joiningDatePlaceHolder = data.joiningDate
      }

      this.updatedKeyPersonIndex = index
      this.keyPersonsModal = true
    },

    /** This method updates the key-person instance with the form details after verifying the the joining date */
    updateKeyPerson () {
      let isJoiningDateBeforeCompanyStart = false
      isJoiningDateBeforeCompanyStart = this.verifyJoiningDate()
      if (!isJoiningDateBeforeCompanyStart) {
        this.selectedSuperior.name = null
        this.keyPersonsModal = false
        this.isKeyPersonObjInvalid = false
        this.showKeyPersonUpdateBtn = false
        this.clearJoiningDate()
        this.newCompany.keyPersons.splice(this.updatedKeyPersonIndex, 1, this.keyPersonObj)
      } else {
        Snackbar({ message: 'Joining date can not be before company started', type: 'is-danger' })
      }
    },

    /**
     * To add the first name of key person on input
     */
    onKeyPersonFirstNameChanged (name, isInvalid, index) {
      this.keyPersonObj.firstName = name
      this.isKeyPersonFirstNameInvalid = isInvalid
    },

    /**
     * To add the second name of key person on input
     */
    HandleKeyPersonSecondName (name, isInvalid, index) {
      this.isKeyPersonSecondNameInvalid = isInvalid
      this.keyPersonObj.lastName = name
    },

    /**
     * To add the designation of key person on input
     */
    HandleKeyPersonDesignation (name, isInvalid, index) {
      this.isKeyPersonDesignationInvalid = isInvalid
      this.keyPersonObj.designation = name
    },

    /**
     * To add the reports to of key person on input
     */
    HandleKeyPersonReportsTo (keyPerson, isInvalid, index) {
      this.keyPersonObj.reportsTo = keyPerson.name
    },

    /**
     * To add the person biography of key person on input
     */
    HandleKeyPersonBiography (name, isInvalid, index) {
      this.isKeyPersonBiographyInvalid = isInvalid
      this.keyPersonObj.biography = name
    },

    /**
     * To add the phone details of key person on input
     */
    HandleKeyPersonPhone (name, isInvalid, index) {
      this.isKeyPersonPhoneInvalid = isInvalid
      this.keyPersonObj.phone = name
    },

    /**
     * To add the email of key person on input
     */
    HandleKeyPersonEmail (name, isInvalid, index) {
      this.isKeyPersonEmailInvalid = isInvalid
      this.keyPersonObj.email = name
    },

    /**
     * To add the peron website of key person on input
     */
    HandleKeyPersonWebsite (name, isInvalid, index) {
      this.isKeyPersonWebsiteInvalid = isInvalid
      this.keyPersonObj.website = name
    },

    /**
     * To add the linkedin of key person on input
     */
    HandleKeyPersonLinkedin (name, isInvalid, index) {
      this.isKeyPersonLinkedinInvalid = isInvalid
      this.keyPersonObj.linkedin = name
    },

    /**
     * To add the twitter of key person on input
     */
    HandleKeyPersonTwitter (name, isInvalid, index) {
      this.isKeyPersonTwitterInvalid = isInvalid
      this.keyPersonObj.twitter = name
    },

    /**
     * To add the new addresses on button click
     */
    addNewAddress () {
      this.addressModal = true
      this.showUpdateBtn = false
      this.addressSubmitted = false
      this.isAddressObjInvalid = true
      this.isAddressCityInvalid = true
      this.isAddressFirstLineInvalid = true
      this.isAddressSecondLineInvalid = false
      this.isAddressLandmarkInvalid = false
      this.isAddressCityInvalid = true
      this.isAddressZipcodeInvalid = true
      this.addressPhoneValidity = []
      this.addressEmailValidity = []
      this.selectedCountry = {
        id: null,
        name: null
      }
      this.selectedState = {
        id: null,
        name: null
      }
      this.statesListLocal = []
      this.addressObj = {
        id: null,
        addressType: null,
        addressFirstLine: null,
        addressSecondLine: null,
        landmark: null,
        country: null,
        state: null,
        city: null,
        zipCode: null,
        phone: [],
        email: []
      }
    },

    /** This method pushes the address details from the form to the new company addresses array */
    addNewAddressObj () {
      this.addressSubmitted = true
      this.isAddressObjInvalid = false
      this.newCompany.addresses.push(this.addressObj)
      this.addressModal = false
    },

    /** This methos opens the address form modal and populated the form fields with the available values */
    openUpdateAddress (data, index) {
      data = JSON.parse(JSON.stringify(data))
      this.addressTypes.forEach(x => {
        if (data.addressType === x.label) {
          this.addressType = x
        }
      })
      if (typeof data.phone === 'string') {
        data.phone = data.phone.split(',')
      }
      if (data.phone && data.phone.length) {
        this.addressPhoneValidity = data.phone.map(phone => !!phone)
      } else {
        this.addressPhoneValidity = []
      }
      if (data.email && data.email.length) {
        this.addressEmailValidity = data.email.map(email => !!email)
      } else {
        this.addressEmailValidity = []
      }
      const country = this.localCountriesList.find(countryObj => countryObj.name === data.country)
      this.selectedCountry = country
      const self = this
      let state = null
      this.getStatesOfCountry(country.id).then(() => {
        self.statesListLocal = self.stateList?.map((stateObj) => ({ id: stateObj.cs_id, name: stateObj.state_name }))
        state = self.statesListLocal.find(stateObj => stateObj.name === data.state)
        this.selectedState = state ? JSON.parse(JSON.stringify(state)) : null
      })
      this.showUpdateBtn = true
      this.addressSubmitted = true
      this.isAddressObjInvalid = true
      this.isAddressCityInvalid = !data.city
      this.isAddressZipcodeInvalid = !data.zipCode
      this.addressObj = data
      this.updateAddressIndex = index
      this.addressModal = true
    },

    /**
     * This is a function to perform update operation on click of the update inside of address modal
     */
    updateAddress () {
      this.showUpdateBtn = false
      this.addressSubmitted = true
      this.isAddressObjInvalid = false
      this.newCompany.addresses.splice(this.updateAddressIndex, 1, this.addressObj)
      this.addressModal = false
      this.selectedCountry = { id: null, name: null }
      this.selectedState = { id: null, name: null }
    },

    /** This method toggles the address form modal */
    onChangeAddressModal (value) {
      this.addressModal = value
      if (!value) {
        this.statesListLocal = []
      }
    },

    /** This method toggles the key-person form modal */
    onChangeKeyPersons (value) {
      this.keyPersonsModal = value
      if (!value) {
        this.clearJoiningDate()
      }
    },

    /** This method adds new phone input field in the address form */
    addNewAddressPhone () {
      if (this.addressObj.phone) {
        this.addressObj.phone.push('')
      } else {
        this.addressObj.phone = ['']
      }
      this.addressPhoneValidity = JSON.parse(JSON.stringify(this.addressPhoneValidity))
      this.addressPhoneValidity.push(false)
    },

    /** This method adds new email input field in the address form */
    addNewAddressEmail () {
      if (this.addressObj.email) {
        this.addressObj.email.push('')
      } else {
        this.addressObj.email = ['']
      }
      this.addressEmailValidity = JSON.parse(JSON.stringify(this.addressEmailValidity))
      this.addressEmailValidity.push(false)
    },

    /**
     * This is a handler function to handel the change in the value of address type field.
     * This function sets the value of financial year end month to the new comapny object
     * @param value {Object} - Input value
     */
    onChangeOfAddressType (value, isInvalid, index) {
      if (value) {
        this.addressType = this.addressTypes[value - 1]
        this.addressObj.addressType = this.addressTypes[value - 1].label
      }
    },

    /**
     * This is a handler function to handel the deselection of the value of address type field.
     * This function sets the value of financial year end month to the new comapny object
     * @param value {Object} - Input value
     */
    onDeselectOfAddressType () {
      this.addressType = null
      this.addressObj.addressType = null
    },

    /**
     * To add the first line of address on input
     */
    HandleAddressFirstLine (name, isInvalid, index) {
      this.isAddressFirstLineInvalid = isInvalid
      this.addressObj.addressFirstLine = name
    },

    /**
     * To add the second line of address on input
     */
    HandleAddressSecondLine (name, isInvalid, index) {
      this.isAddressSecondLineInvalid = isInvalid
      this.addressObj.addressSecondLine = name
    },

    /**
     * To add the landmark of address on input
     */
    HandleAddressLandmark (name, isInvalid, index) {
      this.isAddressLandmarkInvalid = isInvalid
      this.addressObj.landmark = name
    },

    /**
     * Gets called on selection of the country
     * @param {Number} country selected country object
     * @param {Number} FormIndex to get the feild's index in the formgroup to create the address object in newCompany
     */
    onChangeCountryName (country, formIndex) {
      this.addressObj.country = country.name
      const self = this
      this.getStatesOfCountry(country.id).then(() => {
        self.statesListLocal = self.stateList?.map((state) => ({ id: state.cs_id, name: state.state_name }))
      })
      this.addressObj.state = null
      this.selectedState = { id: null, name: null }
    },
    /**
     * Gets called on selection of the country
     * @param {Number} country selected country object
     * @param {Number} FormIndex to get the feild's index in the formgroup to create the address object in newCompany
     */
    onChangeStateName (state, formIndex) {
      this.addressObj.state = state.name
    },

    /**
     * To add the city of address on input
     */
    HandleAddressCity (name, isInvalid, index) {
      this.isAddressCityInvalid = isInvalid
      this.addressObj.city = name
    },

    /**
     * To add the zipcode of address on input
     */
    HandleAddressZipcode (name, isInvalid, index) {
      this.isAddressZipcodeInvalid = isInvalid
      this.addressObj.zipCode = name
    },

    /**
     * To add the phone of address on input
     */
    HandleAddressPhone (name, isInvalid, index) {
      this.addressObj.phone[index] = name
      this.$set(this.addressPhoneValidity, index, !isInvalid)
    },

    /**
     * To add the email of address on input
     */
    HandleAddressEmail (name, isInvalid, index) {
      this.addressObj.email[index] = name
      this.$set(this.addressEmailValidity, index, !isInvalid)
    },

    /**
     * To add the new addresses on button click
     */
    addNewPhone () {
      this.newCompany.phone.push('')
      this.phoneValidity = JSON.parse(JSON.stringify(this.phoneValidity))
      this.phoneValidity.push(false)
    },

    /**
     * This method handles the input of phone numbers
    */
    onInputOfPhone (name, isInvalid, index) {
      this.newCompany.phone[index] = name
      this.$set(this.phoneValidity, index, !isInvalid)
    },

    /**
     * To add the new addresses on button click
     */
    addNewEmail () {
      this.newCompany.email.push('')
      this.emailValidity = JSON.parse(JSON.stringify(this.emailValidity))
      this.emailValidity.push(false)
    },

    /**
     * This method handles the input of email
    */
    onInputOfEmail (name, isInvalid, index) {
      this.newCompany.email[index] = name
      this.$set(this.emailValidity, index, !isInvalid)
    },

    /**
     * This method handles the input in discription input field and sets the value in the new company object.
    */
    handleSocialInput (isInvalid, data) {
      this.anyLinkError = isInvalid
    },

    /**
     * This method handles the input in discription input field and sets the value in the new company object.
    */
    handleWebsiteInput (isInvalid, data) {
      this.isCompanyUrlInvalid = isInvalid
      this.anyLinkError = isInvalid
      this.newCompany.url = data
    },

    /**
     * This is a handler function to handel the competitor comapny selection to set the value in the new company object
     * @param value {Object} - Array of selected companies
     */
    onChangeCompetitorId (value) {
      this.newCompany.competitors = value
    },

    /**
     * This is a handler function to handle the entry on VOE links
     * @param isInvalid {Boolean} - to check if the input is valid
     * @param data {Object} - to receive the data of source links
     */
    handleLinkInput (isInvalid, data) {
      this.anyLinkError = isInvalid
    },

    /** This method validates the input and submits the form
     * @public
     */
    handleSubmit () {
      this.submitted = true
      if (
        !(
          this.isLightLogoInvalid ||
          this.isDarkLogoInvalid ||
          this.isCompanyNameInvalid ||
          this.isCompanyUrlInvalid ||
          this.isDislayNameInvalid ||
          this.isParentCompanyIdInvalid ||
          this.anyLinkError ||
          this.isAliasInvalid ||
          this.isDescriptionInvalid ||
          this.isDUNSInvalid ||
          this.phoneValidity.includes(false) ||
          this.emailValidity.includes(false) ||
          this.ISINValidity.includes(false)
        )
      ) {
        if (this.newCompany.revenue && this.newCompany.revenue.length) {
          let isRevenueValid = false
          if (this.newCompany.revenue.length === 1) {
            if (!this.newCompany.revenue[0].year && !this.newCompany.revenue[0].band) {
              isRevenueValid = true
            }
          }
          if (!isRevenueValid) {
            for (const r of this.newCompany.revenue) {
              if (!r.year || !r.band) {
                Snackbar({ message: 'Please enter valid financial info', type: 'is-danger' })
                return false
              }
            }
          }
          const revenueYearList = this.newCompany.revenue.map(r => r.year)
          const revenueYearSet = new Set(revenueYearList)
          if (revenueYearList.length > revenueYearSet.size) {
            Snackbar({ message: 'Multiple entries for same year in financial info', type: 'is-danger' })
            return false
          }
        }

        if (this.newCompany.employeeSize && this.newCompany.employeeSize.length) {
          let isEmployeeSizeValid = false
          if (this.newCompany.employeeSize.length === 1) {
            if (!this.newCompany.employeeSize[0].year && !this.newCompany.employeeSize[0].band) {
              isEmployeeSizeValid = true
            }
          }
          if (!isEmployeeSizeValid) {
            for (const e of this.newCompany.employeeSize) {
              if (!e.year || !e.band) {
                Snackbar({ message: 'Please enter valid employee Size info', type: 'is-danger' })
                return false
              }
            }
          }
          const employeeSizeYearList = this.newCompany.employeeSize.map(r => r.year)
          const employeeSizeYearSet = new Set(employeeSizeYearList)
          if (employeeSizeYearList.length > employeeSizeYearSet.size) {
            Snackbar({ message: 'Multiple entries for same year in employee size info', type: 'is-danger' })
            return false
          }
        }

        if (!this.newCompany.isPublic) {
          this.newCompany.affiliatedExchanges = [
            {
              exchangeId: null,
              ticker: null,
              isin: null
            }
          ]
        } else {
          for (const e of this.newCompany.affiliatedExchanges) {
            if (!e.exchangeId) {
              Snackbar({ message: 'Please enter valid exchange info', type: 'is-danger' })
              return false
            } else if (!e.ticker) {
              Snackbar({ message: 'Please enter valid ticker info', type: 'is-danger' })
              return false
            }
          }

          // checking for duplicate row
          const isDuplicateExchangeRow = this.newCompany.affiliatedExchanges.length !== uniqWith(this.newCompany.affiliatedExchanges, isEqual).length
          if (isDuplicateExchangeRow) {
            Snackbar({ message: 'Duplicate exchange-ticker records detected', type: 'is-danger' })
            return false
          }
        }

        let foundedDateFormat = ''
        let incDateFormat = ''

        if (this.creationDate) {
          this.newCompany.foundedDate = moment(new Date(this.creationDate), 'DD/MM/YYYY').format('YYYY/MM/DD')
          foundedDateFormat = 'YYYY/MM/DD'
        } else {
          if (this.creationMonth) {
            if (this.creationYear) {
              this.newCompany.foundedDate = this.creationYear + '/' + this.getMonth(this.creationMonth)
              foundedDateFormat = 'YYYY/MM'
            }
          } else if (this.creationYear) {
            this.newCompany.foundedDate = this.creationYear.toString()
            foundedDateFormat = 'YYYY'
          }
        }

        if (this.incorporatedDate) {
          this.newCompany.incorporatedDate = moment(new Date(this.incorporatedDate), 'DD/MM/YYYY').format('YYYY/MM/DD')
          incDateFormat = 'YYYY/MM/DD'
        } else {
          if (this.incorporatedMonth) {
            if (this.incorporatedYear) {
              this.newCompany.incorporatedDate = this.incorporatedYear + '/' + this.getMonth(this.incorporatedMonth)
              incDateFormat = 'YYYY/MM'
            }
          } else if (this.incorporatedYear) {
            this.newCompany.incorporatedDate = this.incorporatedYear.toString()
            incDateFormat = 'YYYY'
          }
        }

        if (this.newCompany.keyPersons && this.newCompany.keyPersons.length) {
          for (let i = 0; i < this.newCompany.keyPersons.length; i++) {
            const keyPerson = this.newCompany.keyPersons[i]
            if (keyPerson.joiningDate) {
              let joiningDateFormat = null
              let startDate = null
              let joiningDate = null
              if (keyPerson.joiningDate.length > 6) {
                joiningDateFormat = 'YYYY/MM/DD'
              } else if (keyPerson.joiningDate.length === 4) {
                joiningDateFormat = 'YYYY'
              } else {
                joiningDateFormat = 'YYYY/MM'
              }
              if (foundedDateFormat) {
                startDate = moment(this.newCompany.foundedDate, foundedDateFormat)
                joiningDate = moment(keyPerson.joiningDate, joiningDateFormat)
              } else if (incDateFormat) {
                startDate = moment(this.newCompany.incorporatedDate, incDateFormat)
                joiningDate = moment(keyPerson.joiningDate, joiningDateFormat)
              }
              if (joiningDate.isBefore(startDate)) {
                Snackbar({ message: 'Joining date can not be before company started', type: 'is-danger' })
                return
              }
            }
          }
        }

        if (this.newCompany.primaryIndustries.some(i => this.newCompany.secondaryIndustries.includes(i))) {
          Snackbar({ message: 'Primary industries and secondary industries can not have common elements.', type: 'is-danger' })
          return false
        }

        const linkArray = this.sourceLinkList
          .filter((e) => e.link)
          .map((e) => e.link)

        const formLinkSet = new Set(
          this.sourceLinkList.filter((e) => e.link).map((e) => e.link)
        )

        if (this.newCompany.url) {
          linkArray.push(this.newCompany.url)
          formLinkSet.add(this.newCompany.url)
        }

        if (formLinkSet.size < linkArray.length) {
          Snackbar({ message: 'Duplicate source links found.', type: 'is-danger' })
          return
        }

        // to find out duplicate links in the social links
        const socialLinkArray = this.websitesLink
          .filter((e) => e.link)
          .map((e) => e.link)

        const socialLinkSet = new Set(
          this.websitesLink.filter((e) => e.link).map((e) => e.link)
        )

        if (this.newCompany.url) {
          socialLinkArray.push(this.newCompany.url)
          socialLinkSet.add(this.newCompany.url)
        }

        if (socialLinkSet.size < socialLinkArray.length) {
          Snackbar({ message: 'Duplicate Social links found in ', type: 'is-danger' })
          return
        }

        this.newCompany.sourceLinks = this.sourceLinkList.filter(sl => sl.link)
        this.newCompany.websitesLinks = this.websitesLink.filter(sl => sl.link).map(sl => { return { ssmId: sl.ssm_id, link: sl.link } })

        const res = this.addCompany(this.newCompany)
        res.then((value) => {
          if (value) {
            this.submitted = false
            this.newCompany = {
              properties: {
                logo_light: null,
                logo_dark: null
              },
              lightLogoName: null,
              darkLogoName: null,
              name: null,
              displayName: null,
              alias: [],
              isParent: true,
              parentCompanyId: null,
              isPublic: false,
              affiliatedExchanges: [
                {
                  exchangeId: null,
                  ticker: null
                }
              ],
              primaryIndustries: [],
              secondaryIndustries: [],
              foundedDate: null,
              incorporatedDate: null,
              fyeMonth: null,
              url: '',
              phone: [],
              email: [],
              description: null,
              sourceLinks: [],
              employeeSize: [
                {
                  year: null,
                  band: null,
                  isDuplicate: false
                }
              ],
              revenue: [
                {
                  year: null,
                  band: null,
                  isDuplicate: false
                }
              ],
              keyPersons: [],
              addresses: [],
              socialLinks: [],
              websitesLinks: [],
              competitors: []
            }

            this.companyCompetitorsList = []
            this.$set(this.newCompany, 'competitors', [])

            this.sourceLinkList = this.dataSourceList.map(s => {
              return { ...s, link: null }
            })
            this.tempAlias = ''
            this.clearDate()
            this.clearIncDate()
            this.clearJoiningDate()
          }
        })
        this.getSocialInfo()
      } else {
        Snackbar({ message: 'Check if all the inputs are valid!', type: 'is-danger' })
      }
    },

    /** This method redirects the user back to the product page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/company')
    },
    initializeYears () {
      const year = new Date().getFullYear()
      const currentYears = Array.from({ length: year - 1900 }, (value, index) => 1901 + index)

      currentYears.reverse().forEach((x, index) => {
        this.employeeYearData.push({
          id: index,
          year: x
        })

        this.revenueYearData.push({
          id: index,
          year: x
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./vue-select-custom.scss";
@import "./vue-tree-select-custom.scss";

::v-deep .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:hover:not(.is-selected), .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selectable:focus:not(.is-selected) {
    background-color: var(--hover-local-dropdown-background) !important;
}

::v-deep .pagination .pagination-next {
    padding-left: 0.25em;
    padding-right: 0.25em;
}

::v-deep .pagination .pagination-previous {
    padding-left: 0.25em;
    padding-right: 0.25em;
}

::v-deep .select {
            width: 100% !important;
          }
.create-form-container {
  position:relative;
  // z-index: 300;
  margin-top: 20px;
  background: var(--primary);
  border-radius: 4px;
  .create-form {
    .columns {
      margin: 0px;
      .column {
        .display-flex {
          display: flex;
          font-size: 12px;
          font-family: Roboto;
          padding: 0px 20px 0px 0px;
          line-height: 20px;
        }
        .relative {
            position: relative;
            .required-error {
              color: red;
              position: absolute;
              font-size: 12px;
            }
          }
        .display-flex div:nth-child(2) {
          padding-left: 20px;
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
        .add-new-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 10px;
          padding-bottom: 10px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: var(--voc-bar-background-color);
            color: white;
            border: none;
            font-size: 0.85rem;
          }

          &.address-btn{
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            font-size: 0.85rem;
            }
          }
        }

        .add-phone-button{
          display: flex;
          justify-content: flex-start;
          padding-top: 10px;
          padding-bottom: 10px;
          i {
            height: 100%;
            padding: 5px 10px;
            background: var(--voc-bar-background-color);
            color: white;
            border: none;
            font-size: 0.85rem;
          }
        }
        .card {
          // background: var(--primary);
          background: var(--background-color);
          overflow: visible;
          .card-header-title {
            color: var(--secondary-text-color);
          }
          .collapse-content {
              .card-content {
                  padding: 10px 10px;
                  color: var(--secondary-text-color);
              }
          }
        }
      }
    }
  }
}

.details-modal {
    .details-inside {
      position:relative;
      z-index: 100;
      width: 100%;
      display: flex;
      color: var(--secondary-text-color);
      font-family: Roboto;
      flex-direction: column;
      background: var(--background-color);
      padding: 25px;
      border-radius: 4px;
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }
      .log {
        display: flex;
        overflow: auto;
      }
    }
}
</style>
